/*
================================================================
IMPORTS
================================================================
*/

var _ = require('underscore');

import 'lazysizes';
import 'cycle2';

import objectFitImages from 'object-fit-images';

var tm_body = document.querySelector('body');


/*
================================================================
ACCESSIBILITY
================================================================
*/

(function () {
    'use strict';

    function keyboardFocus (e) {
        if (e.keyCode !== 9) {
            return;
        }

        switch (e.target.nodeName.toLowerCase()) {
            case 'input':
            case 'select':
            case 'textarea':
                break;
            default:
                document.documentElement.classList.add('keyboard-focus');
                document.removeEventListener('keydown', keyboardFocus, false);
        }
    }

    document.addEventListener('keydown', keyboardFocus, false);
})();


/*
================================================================
POLYFILLS
================================================================
*/

objectFitImages();


/*
================================================================
NAVIGATION
================================================================
*/

var toggle = document.querySelector('#menu-toggle-button');
var menu = document.querySelector('.main-menu-container');
var siteTitle = document.querySelector('.site-title');

toggle.addEventListener('click', toggleMenu);

function toggleMenu(){
    if (menu.classList.contains('is-active')) {
        tm_body.classList.remove('menu--open');
        toggle.setAttribute('aria-expanded', 'false');
        toggle.classList.remove('is-active');
        menu.classList.remove('is-active');
    } else {
        tm_body.classList.add('menu--open');
        menu.classList.add('is-active'); 
        toggle.setAttribute('aria-expanded', 'true');
        toggle.classList.add('is-active');
    }
}

function closeMenu(){
    tm_body.classList.remove('menu--open');
    toggle.setAttribute('aria-expanded', 'false');
    toggle.classList.remove('is-active');
    menu.classList.remove('is-active');
}


